<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <div class="w-100 justify-content-center">
      <div class="error-wrap">
        <div class="row w-100">
          <div class="col-md-6">
            <h1>{{ $t("tempErr.title") }}</h1>
            <p class="sub-title">
              {{ $t("tempErr.subtitle") }}
            </p>
            <p class="sm-text">
              {{ $t("tempErr.desc") }}
            </p>
            <CButton color="info" @click="navigateToHome()">{{
              $t("tempErr.go")
            }}</CButton>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>

      <!--      <CCol md="6">-->
      <!--        <div class="clearfix">-->
      <!--          <h1 class="float-left display-3 mr-4">500</h1>-->
      <!--          <h4 class="pt-3">Houston, we have a problem!</h4>-->
      <!--          <p class="text-muted">The page you are looking for is temporarily unavailable.</p>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; <CInput-->
      <!--          class="mb-3"-->
      <!--          placeholder="What are you looking for?"-->
      <!--        >-->
      <!--          <template #prepend-content><CIcon name="cil-magnifying-glass"/></template> &ndash;&gt;-->
      <!--          &lt;!&ndash; <template #append> &ndash;&gt;-->
      <!--           <CButton color="info"  @click= navigateToHome()>{{$t('errorPage.go')}}</CButton>-->
      <!--          &lt;!&ndash; </template> &ndash;&gt;-->
      <!--        &lt;!&ndash; </CInput> &ndash;&gt;-->
      <!--      </CCol>-->
    </div>
  </CContainer>
</template>

<script>
import { addClassToBody, removeClassBody } from "./../../util/util";
import { mapGetters } from "vuex";

import { navigate } from "./../../router/index";
import { PATHNAME, COMPONENT_NAME } from "./../../util/constants";
export default {
  name: "Page500",
  methods: {
    navigateToHome() {
      if (this.ADMIN_SIGNIN_RES) {
        navigate(PATHNAME.SYSTEM_LOGIN);
      } else {
        navigate(PATHNAME.AGENT_LOGIN);
      }
    },
  },
  computed: {
    ...mapGetters("sSysLogin", {
      ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.PAGE_500);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
